import { Resource } from 'i18next'

export const ptTranslations: Resource = {
  translation: {
    'language-selector': {
      title: 'Seleciona o idioma'
    },
    header: {
      'about-us': 'Sobre',
      token: 'AMZ3 Token',
      roadmap: 'Roadmap',
      'contact-us': 'Fale conosco',
      'white-paper': 'White Paper',
      marketplace: 'Quero fazer parte',
      catalog: 'Mapa',
      'buy-now': 'Adquira agora',
      certificate: 'Meus certificados',
      contact: "Contato",
    },
    home: {
      title: 'Amazon Tree',
      subtitle: 'GREEN-TECHnology',
      text: 'Junte-se a nós na maior transformação digital em apoio à Amazônia em todo o mundo',
      welcome: {
        line1: "Não existe pensar no futuro",
        line2: "sem agir no agora.",
        video: "https://youtu.be/GrPYGzmAmpc",
        "video-label": "Assista ao manifesto"
      },
      greentech: {
        subtitle: "Somos uma greentech para monitoramento e conservação de florestas no mundo.",
        topic1: "Tokens gerados a partir de cada área monitorada", 
        topic2: "Dados registrados via Blockchain com mais transparência", 
        topic3: "Transações em criptomoedas sustentáveis", 
        topic4: "Imersão completa em um metaverso exclusivo", 
        topic5: "Monitoramento constante via satélites", 
      },
      future: {
        title1: "O futuro não se espera.",
        title2: "O futuro se cria.",
        'first-paragraph': "Somos uma greentech de monitoramento e conservação de florestas que possui um ecossistema completo para tokenização de ativos próprios por blockchain, garantindo que cada metro quadrado adquirido tenha seu impacto maximizado de forma positiva para o ambiente e prosperidade econômica social.",
        'second-paragraph': "Existimos para pessoas e marcas protagonistas que vêem a tecnologia não apenas como ferramenta para o crescimento dos negócios, mas como uma força para impulsionar o futuro que queremos através do ESG.",
        'third-paragraph': "Porque movimentos precisam ser liderados por quem tem o real compromisso com a longevidade dos negócios a partir do equilíbrio do mundo.",
        'fourth-paragraph': "Num cenário em que a verdade das empresas tem sido cada vez mais questionadas e ações práticas cada vez mais exigidas pela sociedade em meio a incertezas, inseguranças e pressão em todo o Mundo.",
      },
      prosperity: {
        title1: "Onde a prosperidade empresarial encontra a",
        title2: "prosperidade ambiental.",
        'first-paragraph': "Estima-se que a floresta absorva cerca de 2 bilhões de toneladas de CO2 por ano. Isso significa que, ao proteger e conservar a Amazônia, estamos não apenas preservando uma região única, mas também combatendo ativamente as mudanças climáticas e garantindo um futuro mais estável para o planeta.",
        'second-paragraph': "Nosso ecossistema registra os contratos de prestação de serviços de conservação ambiental e florestal, de um ou mais módulos de terra.",
        'third-paragraph': "São considerados serviços de conservação ambiental e florestal de terras na Amazônia – Brasil a serem prestados pela Amazon Tree: Monitoramento dos Módulos de Terras, bem como promover iniciativas para a manutenção da conservação florestal das áreas registradas na Plataforma Amazon Tree.",
      },
      token: {
        title1: "O pulmão do mundo",
        title2: "protegido por tecnologia de impacto.",
        "first-paragraph": "Nosso ecossistema é financiado através das coleções da Amazon Tree.",
        cta: "Leia mais"
      },
      metaverse: {
        line1: "Participe de eventos virtuais, palestras e workshops exclusivos sobre",
        line2: "sustentabilidade e inovação tecnológica.",
        cta: "Entrar no metaverso"
      }
    },
    "about-token": {
      "green-is": {
        title: "Green is the new black"
      },
      "token-info": {
        block1: {
          title: "O que é",
          subtitle: "UCF AMZ3",
          'first-paragraph': "Ao contratar os serviços da Amazon Tree, você receberá um Certificado UCF (Unidade de Conservação Florestal) referente à área(m²) registrada no Módulo de Terra em forma de token.",
          'second-paragraph': "O Certificado UCF em formato de token é emitido publicamente como Smart Contract, que permite o rastreamento de cada etapa do processo de forma certificada e transparente com a Blockchain.",
          'video': 'https://www.youtube.com/watch?v=DihVyTLAPpc',
          'video-label': 'Assista ao Guia Rápido',
        },
        block2: {
          title: "Certificado UCF AMZ3",
          subtitle1: "Token digital,",
          subtitle2: "impacto real.",
          'first-paragraph': "Tratando-se de uma iniciativa ESG disruptiva que envolve tecnologia de ponta, com a criação e inscrição do Certificado UCF no Blockchain através de Smart Contracts, é possível ativar o Ecossistema da Amazon Tree dando transparência e confiança.",
          'second-paragraph': "Todo Certificado UCF possui informações referenciais. Uma delas é o Contract Address que traça a origem do token. Desta forma, poderá validar em nosso site tais informações.",
        },
        block3: {
          'first-paragraph': "O Certificado UCF (Unidade de Conservação Florestal) referente à área (m²) registrada no token. Ou seja, ao adquiri-lo você participar do ecossistema que promove a conservação, incremento do estoque de carbono e economia circular.",
          'second-paragraph': "Com isso, toneladas de dióxido de carbono são evitadas, reduzindo impactos relacionados ao aquecimento global.",
          cta: "Quero adquirir"
        },
      },
      "collection": {
        title: "Coleções",
        subtitle: "Amazon Tree",
        'first-paragraph': "Nossa primeira coleção SUMAÚMA GENESIS oferece uma forma inovadora e tangível para conservar a Floresta Amazônica. A Sumaúma ecoa a força e a majestade da Floresta Amazônica. É conhecida como a 'árvore da vida', abrigando uma infinidade de espécies em seus galhos e raízes. Esta árvore grandiosa, uma das mais altas e antigas da floresta, serve como inspiração para a nossa coleção.",
        'second-paragraph': "Assim como a imponente Sumaúma é essencial para abrigar a biodiversidade amazônica, cada pessoa que adquire um AMZ3 UCF da coleção Sumaúma I desempenha um papel fundamental e insubstituível na nossa nobre missão de conservar e proteger a floresta para as gerações futuras.",
        cta: "Adquira seu AMZ3 UCF"
      },
      payment: {
        title: "Pagamento",
        subtitle1: "Como pagar através de",
        subtitle2: "cartão de débito, pix ou boleto",
        "first-paragraph": "Através do navegador do seu computador ou do celular, vá até a página da Coleção desejada, escolha a área, navegue até a tela de checkout, preencha as informações e selecione a opção de pagamento 'Real(R$) ou Dólar($)'. Após realizar a transação, o NFT será enviado para a Wallet informada e estará disponível em 'Coleções' na sua carteira.",
        "second-paragraph": "A transação é realizada publicamente como Smart Contract possibilitando o rastreamento de cada etapa do processo de forma certificada e transparente.",

      },
      "payment-solana": {
        title: "Como pagar através de",
        subtitle1: "Wallet Solana",
        subtitle2: "(Web3)",
        topic1: {
          title: "Configure sua Wallet",
          text: [
            "Existem diferentes tipos de Wallet (Carteira Cripto) para Solana. Você pode conectar-se com diversas carteiras.",
            "Geralmente, as carteiras estão disponíveis através de uma extensão do navegador e/ou aplicativo para celular.",
            "highlight::Recomendamos o uso da carteira Phantom.",
            "Caso não possua uma carteira cripto, selecione a opção: “Criar Wallet“.",
            "highlight::⚠️ ATENÇÃO: Você receberá 12 palavras. Essa é a sua “Frase de Recuperação Secreta”, sua chave privada. Essas palavras serão utilizadas para recuperar sua carteira ou realizar login em um novo dispositivo."
          ]
        },
        topic2: {
          title: "Conecte sua Wallet à plataforma",
          text: [
            "Através do navegador do seu computador ou do aplicativo no celular, selecione a carteira desejada e conecte-a.",
            "highlight::⚠️ ATENÇÃO: Não é possível conectar o Wallet pelo navegador padrão do celular, somente pelo navegador do aplicativo Phantom ou outro de  sua preferência."
          ]
        },
        topic3: {
          title: "Adicione Saldo à Wallet",
          text: [
            "Caso não tenha saldo, acesse seu aplicativo Phantom, clique em comprar, selecione a criptomoeda Solana, informe o valor desejado e escolha o método de pagamento. Após essa etapa, o saldo estará disponível em Sol na sua carteira.",
            "highlight::Pronto! Agora você possui saldo em Solana para realizar sua compra."
          ]
        },
        topic4: {
          title: "Escolha seu AMZ3 UCF",
          text: [
            "Nessa etapa, será criado o registro do seu token no Blockchain. Através do navegador do seu computador ou do aplicativo Phantom instalado no seu celular, acesse o 'MAPA', escolha a área de monitoramento, navegue até a tela de checkout, preencha as informações e selecione a opção de pagamento 'Solana'.",
            "highlight::Após realizar a transação, o NFT estará disponível em “Coleções“ na sua carteira.",
            "A transação é realizada publicamente como Smart Contract possibilitando o rastreamento de cada etapa do processo de forma certificada e transparente.",
            
          ]
        },
      }
    },
    co2: {
      title: 'O CO2 e seu papel no cenário atual',
      subtitle:
        'Entenda os impactos e como você pode fazer a diferença. Impactos no Brasil e no Mundo',
      'global-warming': {
        title: 'Aquecimento Global',
        description:
          'Aumento das temperaturas médias globais, levando a eventos climáticos extremos.'
      },
      'biodiversity-loss': {
        title: 'Perda de Biodiversidade',
        description:
          'Extinção de espécies de flora e fauna devido à degradação de habitats naturais.'
      },
      'hydrological-changes': {
        title: 'Mudanças Hidrológicas',
        description:
          'Alterações nos ciclos de chuva, afetando a agricultura e os recursos hídricos disponíveis.'
      }
    },
    about: {
      title: 'Boas vindas a ',
      subtitle: 'Amazon Tree',
      'first-paragraph':
        'A Amazon Tree é uma SA de capital fechado comprometida com a conservação da Amazônia. Sabe-se que a conservação da Amazônia é uma missão urgente e coletiva que requer o envolvimento de pessoas e empresas. Somente através de esforços conjuntos e compromisso genuíno poderemos proteger esse ecossistema precioso e garantir um futuro sustentável para as gerações vindouras.',
      'second-paragraph':
        'A conexão entre pessoas e empresas nessa jornada é poderosa. Empresas que priorizam a conservação podem inspirar seus funcionários e clientes a se tornarem agentes de mudança, estimulando uma cultura de responsabilidade ambiental e engajamento comunitário. Por outro lado, indivíduos engajados podem influenciar empresas a adotar práticas mais sustentáveis ​​e responsáveis, criando um ciclo virtuoso de compromisso com a Amazônia.',
      'third-paragraph':
        'De uma forma inovadora e disruptiva, a Amazon Tree construiu um ecossistema integrado que envolve aquisição de ativos florestais, padronização de contratos inteligentes (smart contracts), tokenização, prestação de serviço de conservação, registro e emissão de Certificado em formato de NFT com segurança e transparência para que empresas e pessoas possam participar de iniciativas que promovem a conservação da floresta.',
      'fourth-paragraph': '',
      'fifth-paragraph':
        'Torne-se um guardião da nossa natureza, enquanto participa de iniciativas ESG de impacto positivo.'
    },
    problems: {
      title: 'Problemas',
      subtitle: 'Impactos na',
      subtitle2: 'fauna e flora',
      'first-paragraph':
        'Mais de 50 bilhões de toneladas de gás carbônico são liberadas anualmente em nossa atmosfera, esse é um dos Gases de Efeito Estufa (GEE) que vai aumentar a temperatura do nosso planeta nos próximos anos.',
      'second-paragraph':
        'Um problema que tem sido combatido através da iniciativa de compensação de carbono por empresas de todo o mundo. ',
      'third-paragraph':
        'Uma pesquisa realizada com 30 empresas pela equipe da Quintessa, mostrou que 100% delas possuem interesse em atuar com soluções para “redução da emissão de carbono”.',
      'fourth-paragraph':
        'No entanto, uma das maiores barreiras dentro do formato atual é a falta de transparência no acompanhamento da captação e direcionamento da verba para projetos de preservação, dificultando investimentos e desestimulando o aumento dessas iniciativas que sofrem por falta de grandes incentivos financeiros.'
    },
    nft: {
      title: 'Soluções',
      subtitle:
        'Gerar Prosperidade Compartilhada Através da Transformação Digital do Mundo Real',
      subtitle2: 'O Ecossistema é mantido por meio de coleções Amazon Tree NFT'
    },
    faq: {
      title: 'Perguntas frequentes',
      'first-item': {
        header: 'Como será realizado o monitoramento das áreas',
        text: 'O monitoramento é realizado com a aplicação de conceitos como Digital Twin através de dados coletados de Satélites, Inventários associados à Inteligência Artificial e Machine Learning para ações preditivas e corretivas.'
      },
      'second-item': {
        header: 'Como funcionará o Metaverso AMZ3?',
        text: 'Com a digitalização das áreas tokenizadas, vamos colocar as pessoas dentro da maior floresta tropical do mundo, um ambiente em que nunca estiveram, a floresta Amazônica através do Metaverso.'
      },
      'third-item': {
        header: 'O que é AMZ3 UCF?',
        text: 'Cada AMZ3 UCF equivale 1 unidade de conservação florestal. Ao adquirir o NFT AMZ3 UCF, é possível participar do ecossistema que promove a conservação e o incremento do estoque de carbono. O NFT AMZ3 UCF é o Certificado de Conservação Florestal referente à quantidade de área (m²) registrada no mesmo. As emissões são limitadas à área total de propriedade da Amazon Tree SA.'
      },
      'fourth-item': {
        header: 'Por que um NFT AMZ3?',
        text: 'Tratando-se de uma iniciativa ESG disruptiva que envolve tecnologia de ponta, com a criação e inscrição do NFT em Blockchain através de Smart Contracts, é possível ativar o Ecossistema da AMZ3 dando início a um ciclo de prosperidade compartilhada através da preservação da floresta.'
      },
      'fifth-item': {
        header: 'Como consultar a exclusividade do NFT AMZ3?',
        text: 'Todo NFT possui informações referenciais. Uma delas é o Contract Address que traz a origem do NFT. Desta forma, poderá validar em nosso site tais informações.'
      }
    },
    roadmap: {
      title: 'Roadmap',
      events: [
        {
          title: 'Outubro 2021',
          description: 'Definição do modelo de negócio',
          position: 'top'
        },
        {
          title: 'Outubro 2022',
          description:
            'Incorporação da área (15.000.000 m²) e constituição da Amazon Tree SA',
          position: 'bottom'
        },
        {
          title: 'Abril 2023',
          description: 'Tokenização da área',
          position: 'top'
        },
        {
          title: 'Maio 2023',
          description: 'Realização do Inventário Florestal',
          position: 'top'
        },
        {
          title: 'Junho 2023',
          description: 'Integração da Plataforma com o Blockchain',
          position: 'bottom'
        },
        {
          title: 'Julho 2023',
          description: 'Implantação da Governança Corporativa',
          position: 'top'
        },
        {
          title: 'Agosto 2023',
          description: 'Incorporação de área (15.000.000 m²)',
          position: 'bottom'
        },
        {
          title: 'Setembro 2023',
          description:
            'Implementação dos indicadores de sustentabilidade, biodiversidade e estoque de carbono. Implementação da metodologia de monitoramento através da digitalização',
          position: 'top'
        },
        {
          title: 'Outubro 2023',
          description:
            'Lançamento público da Coleção AMZ3 SUMAÚMA GENESIS Edição 2024',
          position: 'top'
        },
        {
          title: 'Dezembro 2023',
          description:
            'Incorporação de 60.000.000 de m² de floresta nativa na região da Amazônia',
          position: 'bottom'
        }
      ]
    },
    'contact-us': {
      title: 'Contate-nos',
      subtitle1: 'Seja uma',
      subtitle2: 'empresa',
      subtitle3: 'certificada',
      form: {
        name: 'Nome',
        email: 'Email',
        subject: 'Assunto',
        message: 'Mensagem',
        submit: 'Enviar',
        'error-messages': {
          name: 'Nome é obrigatório',
          email: 'Email é obrigatório',
          subject: 'Subject é obrigatório',
          message: 'Message é obrigatório'
        }
      }
    },
    footer: {
      home: 'Home',
      problems: 'Problems',
      nft: 'Produtos',
      roadmap: 'Roadmap',
      about: 'Sobre',
      solution: 'Solução',
      faq: 'FAQ',
      'white-paper': 'White paper',
      'follow-us': 'Acompanhe nossos canais',
      rights: '©2023 | Amazon Tree® | Todos os Direitos Reservados'
    },
    marketplace: {
      title: 'AMZ3 Platform',
      subtitle: 'Coleções Amazon Tree',
      text: 'O Ecossistema é financiado através das Coleções de NFTs da Amazon Tree de forma transparente e escalável. A primeira etapa do plano de investimento contempla a aquisição de 90.000.000 de m² de áreas na região da Amazônia até dezembro de 2023. É possível participar da maior Transformação Digital do planeta para a preservação da Floresta Amazônica adquirindo os NFTs AMZ3.',
      'first-info-card': '15.000.000m²',
      'second-info-card': 'Floresta Nativa',
      'third-info-card': 'Localidade - Rio Preto da Eva',
      'fourth-info-card': '2024',
      'nft-info-text':
        "Inspirada na majestosa Sumaúma, cada NFT representa um pedaço vital da missão de conservação. A Sumaúma é conhecida como a 'árvore da vida' na Amazônia, abrigando uma infinidade de espécies em seus galhos e raízes. Da mesma forma, cada NFT Sumaúma I abriga a promessa de vida e sustentabilidade, contribuindo para a conservação da floresta Amazônica. E o melhor de tudo, no futuro, essas coleções abrirão as portas para conteúdos exclusivos em nossa plataforma AMZ3, incluindo experiências imersivas em nosso Metaverso e acesso a itens exclusivos da nossa plataforma.",
      'btn-how-buy': 'Quero fazer parte',
      'btn-more-info': 'Saiba mais',
      'first-info-text': 'ano de lançamento',
      'second-info-text': 'árvores disponíveis',
      'third-info-text': 'de m² adquiridos',
      'fourth-info-text':
        'de m² de floresta nativa incorporada na região da Amazônia',
      'payment-info-title':
        'Como pagar seu NFT Cartão de Crédito/Débito, PIX ou Boleto',
      'payment-info-description':
        'Através do navegador do seu computador ou do celular, vá até a página da Coleção desejada, escolha o NFT, navegue até a tela de checkout, preencha as informações e selecione a opção de pagamento “Real(R$) ou Dólar($)“. Após realizar a transação, o NFT será enviado para a Wallet informada e estará disponível em “Coleções“ na sua carteira. A transação é realizada publicamente como Smart Contract possibilitando o rastreamento de cada etapa do processo de forma certificada e transparente.',
      'how-to-pay-title': 'Como pagar seu NFT Wallet Solana (Web3)',
      'how-to-pay-first-step': {
        title: 'Configure sua Wallet',
        description:
          'Existem diferentes tipos de Wallet (Carteira Cripto) para Solana. Você pode conectar-se com diversas carteiras. Recomendamos o uso da carteira Phantom. Geralmente, as carteiras estão disponíveis através de uma extensão do navegador e/ou aplicativo para celular.',
        subDescription: ''
      },
      'how-to-pay-second-step': {
        title: 'Conecte seu Wallet',
        description:
          'Através do navegador do seu computador ou do aplicativo no celular, selecione a carteira desejada e conecte-a. Caso não possua uma carteira cripto, selecione a opção: “Criar Wallet“. Você receberá 12 palavras. Essa é a sua “Frase de Recuperação Secreta”.',
        subDescription:
          'Esta é sua chave privada. Essas palavras serão utilizadas para recuperar sua carteira ou realizar login em um novo dispositivo.'
      },
      'how-to-pay-third-step': {
        title: 'Adicione Saldo',
        description:
          'Caso não tenha saldo, acesse seu aplicativo Phantom, clique em comprar, selecione a criptomoeda Solana, informe o valor desejado e escolha o método de pagamento. Após essa etapa, o saldo estará disponível em Sol na sua carteira.',
        subDescription:
          'Pronto!😉🤩🥳Agora você possui saldo em Solana para realizar sua compra.'
      },
      'how-to-pay-fourth-step': {
        title: 'Escolha seu NFT',
        description:
          'Nessa etapa, será criado o registro do seu NFT no Blockchain. Através do navegador do seu computador ou do aplicativo Phantom instalado no seu celular, vá até a página da Coleção desejada, escolha o NFT, navegue até a tela de checkout, preencha as informações e selecione a opção de pagamento“Solana“. Após realizar a transação, o NFT estará disponível em “Coleções“ na sua carteira. A transação é realizada publicamente como Smart Contract possibilitando o rastreamento de cada etapa do processo de forma certificada e transparente.',
        subDescription:
          'Não é possível conectar o Wallet pelo navegador padrão do celular, somente pelo navegador do aplicativo Phantom ou outro de sua preferência.'
      }
    },
    'catalog-tutorial': {
      title: {
        one: "Falta pouco para",
        two: "obter sua certificação",
      },
      steps: {
        one: 'Navegue pelo mapa e selecione as áreas que você deseja conservar',
        two: 'Confira as informações da área e adicione ao carrinho',
        three: 'Crie sua conta e verifique seu e-mail',
        four: 'Finalize o pagamento com sua wallet ou cartão de crédito e receba seu UCF token',
      },
    },
    catalog: {
      'nfts-list': 'Indicações de tokens',
      'nfts-cart-title': 'Seus tokens',
      'nft-card-detail-line': 'Linha',
      'nft-card-detail-column': 'Coluna',
      'nft-card-detail-in-process': 'Token em processo de emissão',
      'nft-card-detail-copy-token-success':
        'Copiado para area de transferência',
      'nft-card-detail-copy-owner-success':
        'Copiado para area de transferência',
      'nft-card-detail-edition': 'Edição',
      'nft-card-detail-area': 'Area',
      'nft-card-detail-value': 'Valor',
      'nft-card-total-quantity': 'Quantidade de tokens:',
      'nft-card-total-value': 'Valor total da compra:',
      'nft-card-total-checkout-btn': 'Finalizar',
      'nft-resume-check': 'Ver resumo',
      'nft-resume-close': 'Fechar resumo',
      'tab-map': 'Mapa',
      'tab-summary': 'Resumo',
      'tab-tutorial': 'Tutorial',
      'map-popup-button-add': 'Adicionar',
      'map-popup-button-remove': 'Remover',
    },
    cart: {
      title: 'Resumo da compra',
      'area-total': 'Area total',
      'water-total': 'Vapor de Água produzidos:',
      'nft-card-coordinates': 'Coordenadas',
      'nft-card-coordinates-top-left': 'Superior Esquerdo:',
      'nft-card-coordinates-top-right': 'Superior Direito:',
      'nft-card-coordinates-bottom-left': 'Inferior Direito:',
      'nft-card-coordinates-bottom-right': 'Inferior Esquerdo:'
    },
    checkout: {
      title: 'Informe seus dados',
      'crypto-pre-checkout-exception': 'Algo errado aconteceu',
      'signature-exception': 'Algo errado aconteceu',
      'order-exception': 'Algo errado aconteceu',
      'form-name': 'Nome/Empresa',
      'form-email': 'Email',
      'form-wallet': 'Wallet (Carteira)',
      'form-wallet-empty-term':
        'Estou ciente que devo fornecer a CHAVE PÚBLICA do meu Wallet para receber o Certificado UCF em formato de NFT.',
      'form-payment-label': 'Escolha em qual moeda será feito o pagamento',
      'form-payment-info':
        '*Para pagamentos em Boleto Bancário e Pix, a moeda a ser escolhida deve ser Real (BRL)',
      'form-submit-btn': 'Finalizar compra',
      'form-complete-sign-up': 'Estou ciente que devo preencher o cadastro completo antes de finalizar a contratação.',
      'form-error-messages': {
        name: 'Nome é obrigatório',
        email: 'Email é obrigatório',
        wallet: 'Por favor preencha a Wallet (Carteira) ou aceite o alerta',
        'sign-up': 'Por favor aceite o alerta',
        currency: 'Selecione uma moeda'
      }
    },
    success: {
      title: 'Contratação realizada com sucesso!',
      description: `Parabéns pela sua contratação! Agradecemos por escolher a Amazon Tree. Sabemos que cada Certificado UCF em formado de NFT é único e especial, e estamos empolgados em fazer parte dessa jornada com você.`,
      'home-btn': 'Página inicial',
      'dash-btn': 'Ver certificados',
      'wallet-btn': 'Ver carteira'
    },
    fail: {
      title: 'Não foi possível realizar sua contratação',
      description: `Infelizmente tivemos um problema para processar seu pagamento. Para garantir a NFT, você pode tentar novamente com outro método de pagamento.`,
      'retry-btn': 'Tentar novamente'
    },
    'sign-in': {
      title: 'Fazer login',
      form: {
        email: 'Email',
        password: 'Senha',
        'submit-btn': 'Entrar',
        'error-messages': {
          email: 'Email é obrigatório',
          password: 'Senha é obrigatória'
        }
      },
      'forget-password': 'Esqueci minha senha',
      'forget-password-error-message': 'Informe o email',
      'forget-password-success-message':
        'Email de alteração de senha enviado com sucesso',
      'sign-up': 'Criar uma conta',
      'request-error': 'Erro! Não foi possível fazer o login',
      'or': 'OU',
      'google': 'Continue com Google',
      'microsoft': 'Continue com Microsoft',
    },
    'sign-up': {
      title: 'Criar conta',
      'title-step-2': '(Endereço)',
      title1: 'Falta pouco para',
      title2: 'desbloquear todos os recursos da sua conta',
      body1: 'Complete seu cadastro para acessar a área de cliente, baixar seu certificado e acessar todos os benefícios da conta AmazonTree.',
      form: {
        name: 'Nome Completo',
        email: 'Email',
        'confirm-email': 'Confirme seu email',
        password: 'Senha',
        'confirm-password': 'Confirme sua senha',
        country: 'País',
        cpf: 'CPF',
        'zip-code': 'CEP',
        street: 'Endereço',
        district: 'Endereço 2',
        city: 'Cidade',
        state: 'Estado',
        number: 'Número',
        complement: 'Complemento',
        phone: 'Telefone',
        'continue-btn': 'Continuar',
        'submit-btn': 'Completar cadastro',
        'error-messages': {
          name: 'Nome é obrigatório',
          email: 'Email é obrigatório',
          'confirm-email': 'Confirme seu email',
          'email-match': 'Os emails não são iguais',
          password: 'Senha é obrigatória',
          'confirm-password': 'Confirme sua senha',
          'password-match': 'As senha não são iguais',
          'password-strength':
            'A senha precisa pelo menos 8 caracteres, uma letra maiúscula, uma minuscula e um carácter especial',
          country: 'País é obrigatório',
          'zip-code': 'CEP é obrigatório',
          street: 'Endereço é obrigatória',
          district: 'Endereço 2 é obrigatório',
          city: 'Cidade é obrigatória',
          state: 'Estado é obrigatório',
          number: 'Número é obrigatório',
          cpf: 'CPF é obrigatório',
          phone: 'Telefone é obrigatório'
        },
        notification: {
          title: 'Registro de usuário',
          message1: 'Email para validação foi enviado para',
          message2a: 'A validação deve ser concluída em até',
          message2b: 'minutos.',
          message3: 'Se não for concluída dentro desse prazo, será necessário fazer o cadastro novamente.',
        }
      },
      'request-error': 'Erro! Não foi possível criar sua conta'
    },
    notification: {
      'button-types': {
        'try-again': 'Tentar novamente',
        wallet: 'Ver carteira',
        forward: 'Continuar',
        home: 'Ir para home',
      }
    },
    loading: 'Carregando...',
    'privacy-policy': {
      title: 'Politica de Privacidade',
      description:
        'Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. Ao utilizar nossos serviços, você está ciente dessa funcionalidade. Consulte nossa ',
      link: 'Política de Privacidade',
      submit: 'Confirmar'
    },
    'change-password': {
      title: 'Criar nova senha',
      form: {
        password: 'Senha',
        'confirm-password': 'Confirme sua senha',
        'submit-btn': 'Confirmar',
        'error-messages': {
          password: 'Senha obrigatória',
          'confirm-password': 'Confirme sua senha',
          'password-match': 'As senha não são iguais',
          'password-strength':
            'A senha precisa pelo menos 8 caracteres, uma letra maiúscula, uma minuscula e um carácter especial'
        }
      },
      'success-message': 'Senha alterada com sucesso!',
      'request-error': 'Erro! Não foi possível alterar sua senha'
    },
    'user-menu': {
      dashboard: 'Area do cliente',
      trigger: 'Perfil do usuário',
      'change-password': 'Alterar senha',
      'sign-in': 'Login',
      'sign-out': 'Sair'
    },
    wallet: 'Add Carteira',
    'reset-password': {
      title: 'Esqueci minha senha',
      form: {
        email: 'Email',
        'submit-btn': 'Enviar',
        'error-messages': {
          email: 'Email obrigatório'
        }
      },
      'success-message': 'Email de alteração de senha enviado com sucesso',
      'error-message': 'Falha ao enviar o email'
    },
    'verify-phone': {
      title: 'Código enviado para seu telefone',
      form: {
        code: 'Código de verificação',
        'submit-btn': 'Enviar',
        'error-messages': {
          code: 'Código de verificação é obrigatório'
        }
      },
      'try-again-btn': 'Reenviar código',
      'resend-code-success-message':
        'Código de verificação enviado para seu telefone',
      'resend-code-error-message': 'Falha ao reenviar o código de verificação',
      'success-message': 'Código de verificação checado com sucesso',
      'error-message': 'Falha ao checar o código de verificação'
    },
    carousel: {
      banners: {
        solution: {
          title: 'Você é parte da solução ou do problema?',
          'first-description':
            'Você considera importante a conservação da Floresta Amazônica?',
          'second-description':
            'O que você tem feito para causar um impacto real e positivo?'
        },
        environment: {
          title: 'Impactos no ESG',
          subtitle: 'Ambiental',
          'first-description': 'Respirando Vida',
          'second-description': 'através da Conservação'
        },
        social: {
          title: 'Impactos no ESG',
          subtitle: 'Social',
          'first-description': 'Comunidades Fortalecidas,',
          'second-description': 'Futuro Assegurado'
        },
        governance: {
          title: 'Impactos no ESG',
          subtitle: 'Governança',
          'first-description': 'Transparência',
          'second-description': 'e Confiabilidade'
        }
      }
    },
    terms: {
      error: 'Erro ao carregar os termos de adesão'
    },
    mapbox: {
      'check-area-error': 'Erro ao checar disponibilidade de área'
    },
    dashboard: {
      menu: {
        home: 'Voltar para home',
        account: 'Minha conta',
        dashboard: 'Dashboard',
        recent: 'Atividades recentes',
        contracts: 'Meus contratos',
        orders: 'Meus pedidos',
        certificates: 'Meus certificados',
        conservation: 'Dados de conservação',
        'satellite-images': 'Imagens de satélite',
        help: 'Ajuda e suporte',
        tutorials: 'Tutoriais e guias',
        language: {
          label: 'Idioma',
          select: 'Selecione um idioma...'
        }
      },
      'change-profile': {
        title: 'Minha conta',
        form: {
          name: {
            label: '',
            placeholder: 'Nome',
            'error-message': 'Nome é obrigatório'
          },
          email: {
            label: '',
            placeholder: 'Email',
            'error-message': 'Email é obrigatório'
          },
          country: {
            label: '',
            placeholder: 'País',
            'error-message': 'País é obrigatório'
          },
          zipCode: {
            label: '',
            placeholder: 'CEP',
            'error-message': 'CEP é obrigatório'
          },
          'first-address': {
            label: '',
            placeholder: 'Endereço 1',
            'error-message': 'Endereço 1 é obrigatório'
          },
          number: {
            label: '',
            placeholder: 'Número',
            'error-message': 'Número é obrigatório'
          },
          'second-address': {
            label: '',
            placeholder: 'Endereço 2',
            'error-message': 'Endereço 2 é obrigatório'
          },
          complement: {
            label: '',
            placeholder: 'Complemento',
            'error-message': 'Complemento é obrigatório'
          },
          city: {
            label: '',
            placeholder: 'Cidade',
            'error-message': 'Cidade é obrigatório'
          },
          state: {
            label: '',
            placeholder: 'Estado',
            'error-message': 'Estado é obrigatório'
          },
          cpf: {
            label: '',
            placeholder: 'CPF',
            'error-message': 'CPF é obrigatório'
          },
          phone: {
            label: '',
            placeholder: 'Telefone',
            'error-message': 'Telefone é obrigatório'
          },
          instagram: {
            label: '',
            placeholder: 'Instagram'
          },
          facebook: {
            label: '',
            placeholder: 'Facebook'
          },
          discord: {
            label: '',
            placeholder: 'Discord'
          },
          linkedIn: {
            label: '',
            placeholder: 'LinkedIn'
          },
          'cancel-btn': 'Cancelar',
          'submit-btn': 'Salvar',
          'request-success': 'Perfil alterado com sucesso',
          'request-error': 'Não foi possível alterar o perfil'
        }
      },
      'my-certificates': {
        cards: {
          vapor: 'Vapor produzido',
          area: 'Área conservada',
        },
        notification: "Notificações",
        alert: "Você tem certificados para resgatar.",
        title: "Meus certificados",
        filter: {
          retrieved: 'Resgatado',
          pending: 'Pendentes',
          all: 'Todos'
        },
        nft: {
          edition: 'Edição',
          minted: 'Emissão',
          expiration: 'Valido até',
        }
      },
      buttons:{
        rescue:'Resgatar token',
        cancel:'Cancelar',
        send:'Enviar',
        seemore:'Veja mais',
        'download-certificate': 'Baixar Certificado (PDF)'
      },
      rescue: {
        label: 'Informe o endereço da sua carteira Solana',
        placeholder: 'Sua carteira',
      }
    },
    orderStatus:{
      CREATED : 'Criado',
      DISCARDED : 'Descartado',
      VALIDATING_PAYMENT : 'Validando pagamento',
      PAYMENT_REFUSED : 'Pagamento recusado',
      PAYMENT_APPROVED : 'Pagamento aprovado',
      CREATING_TOKEN : 'Criando token',
      TOKEN_MINTED : 'Token criado',
      TOKEN_MOVED_TO_USER : 'Token movido para o usuário'
    },
    helper: {
      'coming-soon': 'Em breve'
    },
    'owner-nfts': {
      'instructions': 'Selecione sua área de observação para localizar no mapa'
    },
    buyContract: {
      agreementTerm: 'Termo de Adesão',
      iAgreeToThe: 'Eu concordo com os',
      termsAndConditions: 'termos e condições',
      confirm:'Confirmar'
    }
  }
}
