import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { NavbarItem } from './navbar-item'
import { ROUTES_PATH } from '../../routes'

type NavbarProps = {
  onClickLink?: () => void
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

export function Navbar({
  className,
  onClickLink = () => {},
  ...props
}: NavbarProps) {
  const { t } = useTranslation()

  return (
    <nav
      className={twMerge(
        'text-amz3white-50 flex text-xl font-medium flex-col gap-8 mt-14 lg:mt-0 lg:ml-[60px] lg:flex-row lg:justify-center',
        className
      )}
      {...props}
    >
      <NavbarItem to={ROUTES_PATH.ABOUT.path} onClick={onClickLink}>
        {t('header.about-us')}
      </NavbarItem>
      <NavbarItem
        className="after:bg-amz3green-100 lg:after:bg-amz3white-50"
        to={ROUTES_PATH.TOKEN.path}
        onClick={onClickLink}
      >
        {t('header.token')}
      </NavbarItem>
      <NavbarItem
        className="after:bg-amz3green-100  lg:after:bg-amz3white-50"
        to={ROUTES_PATH.CATALOG.path}
        onClick={onClickLink}
      >
        {t('header.buy-now')}
      </NavbarItem>
      <NavbarItem
        className="after:bg-amz3green-100  lg:after:bg-amz3white-50"
        to={ROUTES_PATH.DASHBOARD.path}
        onClick={onClickLink}
      >
        {t('header.certificate')}
      </NavbarItem>
    </nav>
  )
}
